<template>
    <div class="putInto">
        <van-cell title="类别" :value="stockPItemtxt" @click="showkindList=true"/>
        <van-popup v-model="showkindList" round position="bottom">
            <van-picker title="选择类别" show-toolbar
                        :columns="kindList"
                        @cancel="showkindList = false"
                        @confirm="onConfirmKindList"
            />
        </van-popup>
        <van-cell title="名称"  :value="stockItemCode" @click="changeStorkName"/>
        <van-popup v-model="showkindchildList" round position="bottom">
            <van-picker title="选择名称" show-toolbar
                        :columns="kindChildList"
                        @cancel="showkindchildList = false"
                        @confirm="onConfirmKindChildList"
            />
        </van-popup>
        <van-cell>
            <van-checkbox v-model="checked" checked-color="#3F7C53">只查本人</van-checkbox>
            <van-field v-if="!checked" label="运营人员" v-model="stockSearch.userName" placeholder="请输入用户名"/>
        </van-cell>
        <div class="button-box">
            <van-button plain type="primary" color="#3F7C53"
                        size="small" @click="allotApply">调拨申请</van-button>
            <van-button type="primary" color="#3F7C53"
                        class="m-l-15" size="small" @click="stockOnSearch">查 询</van-button>
        </div>

        <van-pull-refresh v-model="isLoadingStock"
                          @refresh="stockOnRefresh">
            <van-list
                    v-model="stockLoading"
                    :finished="stockFinished"
                    finished-text="没有更多了"
                    @load="stockOnLoad"
            >

                <div class="public-card stock-box"
                     v-for="(stock,stId) in stockList" :key="stId">
                    <div class="public-flex public-just-between m-b-15">
                        <div class="stock-title">{{stock.itemName}}</div>
                        <van-checkbox
                                :disabled="stock.uniqueNo === uniqueNo"
                                checked-color="#3F7C53"
                                v-model="stock.checked"> </van-checkbox>
                    </div>
                    <div class="public-flex public-just-between m-b-15">
                        <div>运营人员</div>
                        <div class="stock-name">{{stock.userName}}</div>
                    </div>
                    <div class="public-flex public-just-between m-b-15">
                        <div>单价(元)</div>
                        <div class="stock-name">{{stock.unitPrice}}</div>
                    </div>
                    <div class="public-flex public-just-between m-b-15">
                        <div>库存</div>
                        <div class="stock-num">{{stock.num}}</div>
                    </div>
                    <div class="line"> </div>
                    <div class="public-flex public-just-between m-t-15">
                        <div>调拨数量</div>
                        <van-field v-model="stock.transferNum" type="number"
                                   class="stock-name"
                                   :disabled="stock.uniqueNo === uniqueNo"
                                   style="width:90px" placeholder="调拨数量"
                                   :formatter="formatter"
                        />
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>

</template>

<script>
    import {getItemByPItemCode,stockList,
        getParentItem,transfer
    } from '@/utils/putInto'
    import common from '@/utils/common';
    export default {
        name: "stock.vue",
        data(){
            return{
                uniqueNo: common.getItem('uniqueNo'),
                checked: true,
                stockPItemtxt:'请选择',//父类code
                stockItemCode:'请选择',//子类code
                stockSearch:{
                    userName:'',//运营人员姓名
                    //checkMyself	:'' ,// 只查本人  1-是  其他都为否
                    pItemCode:'',//父类code
                    itemCode:'',//子类code
                },
                stockList:[],
                isLoadingStock:false,
                stockLoading:false,
                stockFinished:false,
                pageIndex:1,//页码
                pageSize:10,
                kindList:[],
                kindChildList:[],
                showkindList:false,
                showkindchildList:false,
            }

        },
        created() {
            this.getKindList();
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            //获取类别
            getKindList(){
                getParentItem({}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.kindList = res.data.data.parentItems.map(item=>{
                            item.text = item.pItemName;
                            delete item.pItemName;
                            return item;
                        })
                        this.kindList.unshift({text:'请选择',pItemCode:''});
                    }
                })
            },
            //选择父类别
            onConfirmKindList(value){
                this.stockPItemtxt = value.text;
                this.stockSearch.pItemCode = value.pItemCode;
                //清空子元素
                this.stockItemCode = '请选择';
                this.stockSearch.itemCode = '';

                getItemByPItemCode({pItemCode:value.pItemCode}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.kindChildList = res.data.data.childrens.map(item=>{
                            item.text = item.itemName;
                            delete item.itemName;
                            return item;
                        })
                        this.kindChildList.unshift({text:'请选择',itemCode:''});
                    }
                })
                this.showkindList = false;
            },
            changeStorkName(){
                if(this.stockSearch.pItemCode){
                    this.showkindchildList = true;
                }
            },
            onConfirmKindChildList(value){
                this.stockItemCode = value.text;
                this.stockSearch.itemCode = value.itemCode;
                this.showkindchildList = false;

            },
            stockOnSearch(){
                this.getStockList(true)
            },
            getStockList(flag){
                if(flag){
                    this.stockList = []
                    this.pageIndex = 1
                }
                let data = {
                    checkMyself:this.checked?1:'',
                    pageIndex:this.pageIndex,//页码
                    pageSize:this.pageSize,
                    ...this.stockSearch,
                }
                stockList(data).then(res=>{
                    this.stockLoading = false;
                    if(res.code===200 && res.data.businessCode===200){
                        this.stockFinished = !res.data.data.hasNextPage;
                        if (this.pageIndex === 1) {
                            this.stockList = res.data.data.inStockItemList;
                        } else {
                            this.stockList = this.stockList.concat(res.data.data.inStockItemList);
                        }
                        if (!this.stockFinished) {
                            this.pageIndex++;
                        }
                    }
                })
            },
            stockOnLoad(){
                this.getStockList();
            },
            stockOnRefresh(){
                this.isLoadingStock = false
                this.stockList = []
                this.pageIndex = 1
                this.getStockList()
            },
            allotApply(){
                let arr = [];
                let oldArr = this.stockList;
                for(let i = 0; i < oldArr.length ; i++) {
                    let item = oldArr[i];
                    if(item.checked && !item.transferNum){
                        this.$message.warning('请输入调拨数量 !');
                        return;
                    }
                    if(item.checked && item.transferNum){
                        if(item.transferNum > item.num ){
                            this.$message.warning('库存不足 !');
                            return;
                        }
                        let obj = {}
                        obj.id = item.id;
                        obj.transferNum = item.transferNum;
                        arr.push(obj);
                    }
                }
                if(arr.length <= 0){
                    this.$message.warning('请选择调拨库存!')
                    return;
                }
                console.log('调拨数据',arr);
                transfer({transferGoodsList:arr}).then(res=>{
                    console.log('调拨数据',res)
                    if(res.code===200 && res.data.businessCode===200){
                        this.$message.success('调拨申请成功 !')
                        this.getStockList();
                    }
                })

            }
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";

</style>
