<template>
    <div class="putInto">
        <van-tabs v-model="active" color="#3F7C53" title-active-color="#3F7C53" @click="changeTab">
            <van-tab v-for="(item,idx)  in tabList" :key="idx" :title="item"> </van-tab>
        </van-tabs>
        <div v-if="active===0">
            <van-cell size="small"
                      title="采购日期"
                      :value="date"
                      @click="show = true" />
            <van-calendar color="#3F7C53" v-model="show"
                          :min-date="minDate"
                          :default-date ='defaultDate'
                          type="range" @confirm="onConfirm" />
            <div class="button-box">
                <van-button plain type="primary" color="#3F7C53" size="small" class="m-l-15"
                            @click="updatePurchace">编辑</van-button>
                <van-button plain type="primary" color="#3F7C53" size="small" class="m-l-15"
                            @click="deletePurchase">删除</van-button>
                <van-button plain type="primary" color="#3F7C53" size="small" class="m-l-15"
                            @click="addGoodShow=true">新增</van-button>
                <van-button type="primary" color="#3F7C53" size="small"
                            class="m-l-15" @click="search">查 询</van-button>
            </div>
            <van-pull-refresh v-model="isLoadingGoods"
                              @refresh="goodsOnRefresh">
                <van-list
                        v-model="goodsLoading"
                        :finished="goodsFinished"
                        finished-text="没有更多了"
                        @load="goodsOnLoad">
                    <div class="public-card put-storage" v-for="(goods,idx) in goodsList" :key="idx">
                        <div class="put-title">{{goods.spNo}}-{{goods.supplier}}</div>
                        <div class="public-flex m-b-15">
                            <div class="put-time">{{goods.purchaseDate}}</div>
                            <div class="put-time">{{goods.type}} - {{goods.itemName}}</div>
                            <van-checkbox
                                    checked-color="#3F7C53"
                                    v-model="goods.checked"> </van-checkbox>
                        </div>
                        <div class="public-flex public-just-around">
                            <div class="column-display">
                                <div class="put-unit-price">{{goods.unitPrice}}</div>
                                <div class="all-title">单价(元)</div>
                            </div>
                            <div class="column-display">
                                <div class="put-num">{{goods.num}}</div>
                                <div class="all-title">数量</div>
                            </div>
                            <div class="column-display">
                                <div class="put-total">{{goods.totalPrice}}</div>
                                <div class="all-title">合计(元)</div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>


            <van-dialog v-model="addGoodShow" title="新增/修改"
                        :showConfirmButton="false"
                        :closeOnClickOverlay="true"
            >
                <van-form>
                    <van-field
                            required
                            v-model="addGoodsObj.supplier"
                            name="供应商"
                            label="供应商"
                            placeholder="请输入"
                            :rules="[{ required: true,  message: '请填写供应商' }]"
                    />
                    <van-field
                            required
                            v-model="goodName"
                            clickable
                            name="物品名称"
                            label="物品名称"
                            placeholder="请选择"
                            @click="showPicker = true"
                            :rules="[{ required: true, message: '请选择物品名称' }]"
                    />
                    <van-field
                            required
                            v-model="addGoodsObj.num"
                            name="数量"
                            label="数量"
                            placeholder="请输入"
                            type="number"
                            :rules="[{ required: true, message: '请填写数量' }]"
                            :formatter="formatter"
                    />
                    <van-field
                            required
                            v-model="addGoodsObj.unitPrice"
                            name="单价"
                            label="单价(元)"
                            placeholder="请输入"
                            type="number"
                            :rules="[{ required: true, message: '请填写单价' }]"
                    />
                    <van-field
                            required
                            v-model="addGoodsObj.purchaseDate"
                            clickable
                            name="采购日期"
                            label="采购日期"
                            :value="addGoodsObj.purchaseDate"
                            placeholder="请选择日期"
                            @click="showCalendar = true"
                            :rules="[{ required: true, message: '请选择采购日期' }]"
                    />
                    <van-cell title="报备单"  :value="stateText" @click="showState=true"/>
                    <van-popup v-model="showState" round position="bottom">
                        <van-picker title="报备单" show-toolbar
                                    :columns="reportNoList"
                                    @cancel="showState = false"
                                    @confirm="changeReport"
                        />
                    </van-popup>
                    <div class="button-box m-b-15">
                        <van-button plain type="primary" color="#3F7C53" size="small" @click="addCancel">取 消</van-button>
                        <van-button type="primary" color="#3F7C53" size="small" @click="addAffirm">确 认</van-button>
                    </div>
                </van-form>
            </van-dialog>
            <van-popup v-model="showPicker" position="bottom">
                <van-cascader
                        title="请选择物品名称"
                        :options="columns"
                        @close="showPicker = false"
                        active-color="#3f7c53"
                        :field-names="opNames"
                        @finish="changeGoodsName"
                />
            </van-popup>
            <van-calendar v-model="showCalendar" :min-date="minDate"
                          @confirm="onConfirmTime" color="#3F7C53"  />
        </div>
        <div v-if="active===1">
            <reportToList> </reportToList>
        </div>
        <div v-if="active===2">
            <stock> </stock>
        </div>
        <div v-if="active===3">
            <allot> </allot>
        </div>
        <div v-if="active===4">
            <puts-store> </puts-store>
        </div>
    </div>
</template>

<script>
    import {goodsList,getAllItem,add,deletePurchase,reportList} from '@/utils/putInto';
    import reportToList from './reportList';
    import stock from './stock';
    import allot from './allot';
    import putsStore from './putsStore';
    import {Toast} from "vant";
    import common from '@/utils/common'
    export default {
        name: "putInto.vue",
        components:{reportToList,stock,allot,putsStore},
        data(){
            return{
                uniqueNo: common.getItem('uniqueNo'),
                active:0,
                tabList:[
                    '采购',
                    '报备',
                    '库存',
                    '调拨',
                    '出库'
                ],
                date:'',
                defaultDate:[new Date((new Date()  - 1000 * 60 * 60 * 24 * 60)),new Date()] ,
                show:false,
                minDate: new Date(2022,1,1),
                purchaseStartDate:'',//采购开始日期 yyyy-MM-dd
                purchaseEndDate:'',//采购结束日期 yyyy-MM-dd
                pageIndex:1,//页码
                pageSize:10,
                goodsList:[],
                stateText:'请选择',
                spNo:'',
                showState:false,
                reportNoList:[],
                goodsLoading:false,
                goodsFinished:false,
                isLoadingGoods:false,
                addGoodShow:false,
                showPicker:false,
                showCalendar:false,
                columns:[],
                opNames:{
                    text: 'itemName',
                    value: 'itemCode',
                    children: 'children',
                },
                goodName:'',
                addGoodsObj:{
                    id:"",//编号
                    supplier:'',//供应商
                    itemCode:'',//类别代码
                    num:'',//数量
                    unitPrice:'',//单价
                    purchaseDate:'',//采购日期 yyyy-MM-dd
                    spNo:'',
                },

            }
        },
        created(){
            //获取当前时间
            let myDate = new Date();
            let startY = myDate.getFullYear();
            let startM = myDate.getMonth() + 1;
            let startD = myDate.getDate();
            let myArr = startY +'-'+ (startM < 10 ? '0' + startM :startM) +'-'+(startD< 10 ? '0'+ startD : startD);
            //获取三十天前日期
            let lw = new Date(myDate - 1000 * 60 * 60 * 24 * 60);//最后一个数字30可改，30天的意思
            let lastY = lw.getFullYear();
            let lastM = lw.getMonth() + 1;
            let lastD = lw.getDate();
            let startdate = lastY +'-'+ (lastM < 10 ? '0' + lastM : lastM) +'-'+(lastD < 10 ? '0'+ lastD : lastD);
            this.date = startdate+'至'+ myArr;
            this.purchaseStartDate = startdate;
            this.purchaseEndDate = myArr;
            this.getTradeName();
            this.getReportList();
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            mounted() {
                this.active = common.getItem('active')?common.getItem('active'):0;
                common.removeItem('active');
            },
            //选择物品名称
            changeGoodsName(value){
                this.goodName = value.selectedOptions.map((option) => option.itemName).join('/');
                this.addGoodsObj.itemCode = value.value?value.value:'';
                this.showPicker = false;
            },
            getReportList(){
                let data = {
                    reportStartDate:'',//开始日期 yyyy-MM-dd
                    reportEndDate:'',//结束日期 yyyy-MM-dd
                    pageIndex:this.pageIndex,//页码
                    pageSize:this.pageSize
                }
                reportList(data).then(res=>{
                    if(res.code===200 && res.data.businessCode===200){
                        let arr = res.data.data.dataList;
                        this.reportNoList = arr.map(item=>{
                            item.text = item.spNoAndMoney;
                            return item;
                        })
                        this.reportNoList.unshift({text:'请选择',spNo:''});
                    }
                })
            },
            changeReport(value){
                this.stateText = value.text==='请选择'?'':value.text;
                this.addGoodsObj.spNo = value.spNo;
                this.showState = false;
            },
            onConfirmTime(date){
                this.addGoodsObj.purchaseDate = `${date.getFullYear()}-${(date.getMonth() + 1)
                < 10 ?'0'+(date.getMonth() + 1):(date.getMonth() + 1)}-${date.getDate() < 10 ? '0'+date.getDate():date.getDate()}`;
                this.showCalendar = false;
            },
            //切换专栏
            changeTab(){
                if(this.active === 0){
                    this.getGoodsList(true);
                }
            },
            //取消按钮
            addCancel(){
                this.addGoodShow = false;
                this.goodName = '';//物品名称
                this.stateText='请选择';
                this.addGoodsObj = {
                    id:"",
                    supplier:'',//供应商
                    itemCode:'',//类别代码
                    num:'',//数量
                    unitPrice:'',//单价
                    purchaseDate:'',//采购日期 yyyy-MM-dd
                    spNo: '',
                }
            },
            //新增确认
            addAffirm(){
                if(!this.addGoodsObj.supplier){
                    this.$message.warning('请填写供应商 !');
                    return;
                }
                if(!this.addGoodsObj.itemCode){
                    this.$message.warning('请选择物品名称 !');
                    return;
                }
                if(!this.addGoodsObj.num){
                    this.$message.warning('请输入数量 !');
                    return;
                }
                if(!this.addGoodsObj.unitPrice){
                    this.$message.warning('请输入单价 !');
                    return;
                }
                if(!this.addGoodsObj.purchaseDate){
                    this.$message.warning('请选择采购日期 !');
                    return;
                }
                console.log("uniqueNo：",this.uniqueNo);
                if(!this.addGoodsObj.spNo&&this.uniqueNo!=='M1351725398177902592'){
                    Toast('请选择报备单 !');
                    return;
                }
                this.addGoodsObj.unitPrice = this.addGoodsObj.unitPrice?parseFloat(this.addGoodsObj.unitPrice).toFixed(2):'';

                add(this.addGoodsObj).then(res=>{
                    if(res.code===200&&res.data.businessCode===200){
                        this.$message.success('保存成功 !');
                        this.addGoodShow = false;
                        this.getGoodsList(true);
                        this.goodName = '';//物品名称
                        this.stateText='请选择';
                        this.addGoodsObj = {
                            id:"",
                            supplier:'',//供应商
                            itemCode:'',//类别代码
                            num:'',//数量
                            unitPrice:'',//单价
                            purchaseDate:'',//采购日期 yyyy-MM-dd
                            spNo: '',
                        }
                    }
                })

            },
            //获取所有种类及物品名称
            getTradeName(){
                getAllItem({}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.columns =  this.getTreeData(res.data.data);
                    }
                })
            },
            getTreeData(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].children.length < 1) {
                        // children若为空数组，则将children设为undefined
                        data[i].children = undefined;
                    } else {
                        // children若不为空数组，则继续 递归调用 本方法
                        this.getTreeData(data[i].children);
                    }
                }
                return data;
            },

            //采购入库的时间处理
            formatDate(date) {
                return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ?'0'+(date.getMonth() + 1):(date.getMonth() + 1)}-${date.getDate() < 10 ? '0'+date.getDate():date.getDate()}`;
            },
            onConfirm(date) {
                const [start, end] = date;
                this.show = false;
                this.date = `${this.formatDate(start)}至${this.formatDate(end)}`;
                this.purchaseStartDate = this.formatDate(start);
                this.purchaseEndDate = this.formatDate(end);
            },
            //采购入库查询
            search(){
                this.getGoodsList(true);
            },
            //点击修改
            updatePurchace(){
                let arr = [];
                let key;
                let oldArr = this.goodsList;
                for(let i = 0; i < oldArr.length ; i++) {
                    let item = oldArr[i];
                    if(item.checked){
                        let obj = {}
                        obj.id = item.id;
                        arr.push(obj);
                        key=i;
                    }
                }
                if(arr.length <= 0){
                    this.$message.warning('请选择需编辑的入库物品!')
                    return;
                }
                if(arr.length > 1){
                    this.$message.warning('请仅选择一条需编辑的入库物品!')
                    return;
                }
                this.addGoodShow = true;
                this.addGoodsObj.id=this.goodsList[key].id;
                this.addGoodsObj.supplier=this.goodsList[key].supplier;
                this.addGoodsObj.itemCode=this.goodsList[key].itemCode;
                this.goodName=this.goodsList[key].type+"/"+this.goodsList[key].itemName;
                this.addGoodsObj.num=this.goodsList[key].num;
                this.addGoodsObj.unitPrice=this.goodsList[key].unitPrice;
                this.addGoodsObj.purchaseDate=this.goodsList[key].purchaseDate;
                this.addGoodsObj.spNo=this.goodsList[key].spNo;
                this.stateText=this.goodsList[key].spNo;
            },

            deletePurchase(){
                let arr = [];
                let oldArr = this.goodsList;
                for(let i = 0; i < oldArr.length ; i++) {
                    let item = oldArr[i];
                    if(item.checked){
                        let obj = {}
                        obj.id = item.id;
                        arr.push(obj);
                    }
                }
                if(arr.length <= 0){
                    this.$message.warning('请选择需删除的入库物品!')
                    return;
                }
                if(arr.length > 1){
                    this.$message.warning('请仅选择一条需删除的入库物品!')
                    return;
                }
                console.log('arr撤销数据',arr);
                deletePurchase({transferGoodsList:arr}).then(res=>{
                    console.log('删除数据',res);
                    if(res.code===200 && res.data.businessCode===200){
                        this.$message.success('入库记录删除成功 !')
                        this.search();
                    }
                })

            },
            //采购入库上拉加载
            goodsOnLoad(){
                this.getGoodsList();
            },
            getGoodsList(flag){
                if(flag){
                    this.siteList = []
                    this.pageIndex = 1
                }
                let data = {
                    purchaseStartDate:this.purchaseStartDate,//采购开始日期 yyyy-MM-dd
                    purchaseEndDate:this.purchaseEndDate,//采购结束日期 yyyy-MM-dd
                    pageIndex:this.pageIndex,//页码
                    pageSize:this.pageSize
                }
                goodsList(data).then(res=>{
                    this.goodsLoading = false;
                    if(res.code===200 && res.data.businessCode===200){
                        this.goodsFinished = !res.data.data.hasNextPage;
                        if (this.pageIndex === 1) {
                            this.goodsList = res.data.data.purchaseGoodsList;
                        } else {
                            this.goodsList = this.goodsList.concat(res.data.data.purchaseGoodsList);
                        }
                        if (!this.goodsFinished) {
                            this.pageIndex++;
                        }
                    }

                })
            },
            goodsOnRefresh() {
                this.isLoadingGoods = false
                this.goodsList = []
                this.pageIndex = 1
                this.getGoodsList()
            },


        }
    }
</script>

<style lang="less" >
    @import "../../style/putInto.less";
    .van-tab{
        font-size: 0.35rem;
    }
    .van-cell{
        font-size: 12px;
    }
</style>
