<template>
    <div class="putInto">
        <van-cell title="类别" :value="stockPItemtxt" @click="showkindList=true"/>
        <van-popup v-model="showkindList" round position="bottom">
            <van-picker title="选择类别" show-toolbar
                        :columns="kindList"
                        @cancel="showkindList = false"
                        @confirm="onConfirmKindList"
            />
        </van-popup>
        <van-cell title="名称"  :value="stockItemCode" @click="changeStorkName"/>
        <van-popup v-model="showkindchildList" round position="bottom">
            <van-picker title="选择名称" show-toolbar
                        :columns="kindChildList"
                        @cancel="showkindchildList = false"
                        @confirm="onConfirmKindChildList"
            />
        </van-popup>
        <van-cell title="状态"  :value="stateText" @click="showState=true"/>
        <van-popup v-model="showState" round position="bottom">
            <van-picker title="选择状态" show-toolbar
                        :columns="stateList"
                        @cancel="showState = false"
                        @confirm="changeStateName"
            />
        </van-popup>
        <van-cell>
            <van-checkbox v-model="checked" checked-color="#3F7C53">只查本人</van-checkbox>
            <van-field v-if="!checked" label="运营人员" v-model="stockSearch.userName" placeholder="请输入用户名" />
        </van-cell>
        <div class="button-one">
            <van-button type="primary" color="#3F7C53" size="small" @click="stateOnSearch">查 询</van-button>
        </div>


        <van-pull-refresh v-model="isLoadingState"
                          @refresh="stockOnRefresh">
            <van-list
                    v-model="stateLoading"
                    :finished="stateFinished"
                    finished-text="没有更多了"
                    @load="stateOnLoad"
            >
                <div class="public-card state-padding m-b-15"
                     v-for="(allot,idx) in allotList" :key="idx"
                >
                    <div>
                        <div class="state-title">{{allot.name}}</div>
                        <van-image
                                class="state-img"
                                width="110"
                                height="45"
                                :src="allot.applicationStatus==='CONFIRM'?
                                confirmImg:allot.applicationStatus==='WAIT_CONFIRM'?
                                noConfirmImg:allot.applicationStatus==='TURN'?rejectImg:''"
                        />
                    </div>
                    <div class="public-flex public-just-between m-t-15">
                        <div class="state-info">
                            <div>申请时间: {{allot.applicationDateTime}}</div>
                            <div class="m-t-10">申请人: {{allot.applicantName}}</div>
                            <div class="m-t-10">调出人: {{allot.callOutName}}</div>
                        </div>
                        <div class="state-num">{{allot.transferNum}}</div>
                    </div>
                    <div class="public-flex public-just-between m-t-15 line"
                         v-if="allot.applicationStatus==='WAIT_CONFIRM' && (uniqueNo === allot.callOutUniqueNo)"
                    >
                        <div class="state-reject m-t-15"
                         @click="stateReject(allot.id)">驳 回</div>
                        <div class="state-confim m-t-15"
                             style="border-left:1px solid #F0F2F5"
                             @click="stateConfirm(allot.id)"
                        >确 认</div>
                    </div>


                </div>
            </van-list>
        </van-pull-refresh>
    </div>

</template>

<script>
    import {getParentItem,getItemByPItemCode,applyList,applyConfirm} from '@/utils/putInto';
    import common from '@/utils/common';
    export default {
        name: "allot.vue",
        data(){
            return{
                uniqueNo: common.getItem('uniqueNo')?common.getItem('uniqueNo'):this.$route.query.uniqueNo,
                kindList:[],
                kindChildList:[],
                checked: true,
                stockPItemtxt:'请选择',//父类code
                stockItemCode:'请选择',//子类code
                showkindList:false,
                showkindchildList:false,
                stockSearch:{
                    userName:'',//运营人员姓名
                    //checkMyself	:'' ,// 只查本人  1-是  其他都为否
                    pItemCode:'',//父类code
                    itemCode:'',//子类code
                    applicationStatus:'',//状态
                },
                pageIndex:1,//页码
                pageSize:10,
                stateList:[
                    {
                        text:'请选择',
                        value:''
                    },
                    {
                        text:'已确认',
                        value:'CONFIRM'
                    },
                    {
                        text:'待确认',
                        value:'WAIT_CONFIRM'
                    },
                    {
                        text:'驳回',
                        value:'TURN'
                    }
                ],
                stateText:'请选择',
                showState:false,
                confirmImg:require('../../assets/img/putInto/confirmed.png'),
                rejectImg:require('../../assets/img/putInto/rejected.png'),
                noConfirmImg:require('../../assets/img/putInto/to-be-confirmed.png'),
                stateLoading:false,
                stateFinished:false,
                allotList:[],
                isLoadingState:false
            }
        },
        created() {
            this.getKindList();
        },
        methods:{
            //获取类别
            getKindList(){
                getParentItem({}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.kindList = res.data.data.parentItems.map(item=>{
                            item.text = item.pItemName;
                            delete item.pItemName;
                            return item;
                        })
                        this.kindList.unshift({text:'请选择',pItemCode:''});
                    }
                })
            },
            //选择父类别
            onConfirmKindList(value){
                this.stockPItemtxt = value.text;
                this.stockSearch.pItemCode = value.pItemCode;
                //清空子元素
                this.stockItemCode = '请选择';
                this.stockSearch.itemCode = '';

                getItemByPItemCode({pItemCode:value.pItemCode}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.kindChildList = res.data.data.childrens.map(item=>{
                            item.text = item.itemName;
                            delete item.itemName;
                            return item;
                        })
                        this.kindChildList.unshift({text:'请选择',itemCode:''});
                    }
                })
                this.showkindList = false;
            },
            changeStorkName(){
                if(this.stockSearch.pItemCode){
                    this.showkindchildList = true;
                }
            },
            onConfirmKindChildList(value){
                this.stockItemCode = value.text;
                this.stockSearch.itemCode = value.itemCode;
                this.showkindchildList = false;
            },
            changeStateName(value){
                this.stateText = value.text;
                this.stockSearch.applicationStatus = value.value;
                this.showState = false;
            },
            stateOnSearch(flag){
                if(flag){
                    this.allotList = []
                    this.pageIndex = 1
                }
                let data = {
                    checkMyself:this.checked?1:'',
                    pageIndex:this.pageIndex,//页码
                    pageSize:this.pageSize,
                    ...this.stockSearch,
                }
                applyList(data).then(res=>{
                    this.stateLoading = false;
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.stateFinished = !res.data.data.hasNextPage;
                        if (this.pageIndex === 1) {
                            this.allotList = res.data.data.list;
                        } else {
                            this.allotList = this.allotList.concat(res.data.data.list);
                        }
                        if (!this.stateFinished) {
                            this.pageIndex++;
                        }
                    }
                })
            },
            stateOnLoad(){
                this.stateOnSearch();
            },
            stockOnRefresh(){
                this.isLoadingState = false
                this.allotList = []
                this.pageIndex = 1
                this.stateOnSearch()
            },
            stateConfirm(id){
                applyConfirm({id:id,
                    applicationStatus:'CONFIRM'}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.$message.success('确认成功 !')
                        this.stateOnSearch();
                    }
                })
            },
            stateReject(id){
                applyConfirm({id:id,
                    applicationStatus:'TURN'}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.$message.success('驳回成功 !')
                        this.stateOnSearch();
                    }
                })

            }
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .state-padding{
        padding: 18px 40px 15px 15px;
        position: relative;
        .state-title{
            font-size: 16px;
            font-weight: bold;
            color: #191F25;
        }
        .state-img{
            position: absolute;
            top:0;right: 0;
        }
        .state-info{
            font-size: 13px;
            font-weight: 500;
            color: #7E8185;
        }
        .state-num{
            font-size: 24px;
            font-weight: bold;
            color: #F25555;
        }
        .state-reject{
            width:50%;
            color:#F25555;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }
        .state-confim{
            width:50%;
            color:#3F7C53;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }
        .line{
            border-top:1px solid #F0F2F5;
        }

    }


</style>
