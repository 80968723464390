<template>
    <div class="putInto">
        <van-cell title="类别" :value="stockPItemtxt" @click="showkindList=true"/>
        <van-popup v-model="showkindList" round position="bottom">
            <van-picker title="选择类别" show-toolbar
                        :columns="kindList"
                        @cancel="showkindList = false"
                        @confirm="onConfirmKindList"
            />
        </van-popup>
        <van-cell title="名称"  :value="stockItemCode" @click="changeStorkName"/>
        <van-popup v-model="showkindchildList" round position="bottom">
            <van-picker title="选择名称" show-toolbar
                        :columns="kindChildList"
                        @cancel="showkindchildList = false"
                        @confirm="onConfirmKindChildList"
            />
        </van-popup>
        <van-field label="站点名称" v-model="stockSearch.stationName"  placeholder="请输入" />
        <van-cell>
            <van-checkbox v-model="checked" checked-color="#3F7C53" @click="switchover">只查本人</van-checkbox>
            <van-field v-if="!checked" label="运营人员" v-model="stockSearch.userName" placeholder="请输入用户名" />
        </van-cell>
        <div class="button-box">
            <van-button  type="primary" class="m-l-15" color="#3F7C53" size="small" @click="cancelStockJump">出库撤回</van-button>
            <van-button  type="primary" class="m-l-15" color="#3F7C53" size="small" @click="otherJump">其他出库</van-button>
            <van-button  type="primary" class="m-l-15"  color="#3F7C53" size="small" @click="jumpTo">出库到站点</van-button>
            <van-button type="primary" class="m-l-15" color="#3F7C53" size="small" @click="searchList">查 询</van-button>
        </div>

        <van-pull-refresh v-model="isLoadingStation"
                          @refresh="stationOnRefresh">
            <van-list
                    v-model="stationLoading"
                    :finished="stationFinished"
                    finished-text="没有更多了"
                    @load="stationOnLoad"
            >

                <div class="public-card allot-padding m-b-15"
                     v-for="(sta,idx) in stationList" :key="idx"
                >
                    <div class="public-flex public-just-between m-t-15">
                        <div class="station-title">{{sta.name}}</div>
                        <div class="station">{{sta.stationName}}</div>
                        <van-checkbox
                                :disabled="(sta.isMyself != '1')"
                                checked-color="#3F7C53"
                                v-model="sta.checked"> </van-checkbox>
                    </div>
                    <div class="public-flex public-just-between m-t-15">
                        <div class="station-info">
                            <div>{{sta.userName}}  {{sta.outOfStockType}}</div>
                            <div class="m-t-10">{{sta.outOfTockDateTime}}</div>
                            <div class="m-t-10">{{sta.descReason}}</div>
                        </div>
                        <div class="station-num">{{sta.outOfStockNum}}</div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>

    </div>

</template>

<script>
    import {getParentItem,getItemByPItemCode,outStockList,cancelJump} from '@/utils/putInto';
    import common from '@/utils/common';
    export default {
        name: "putsStore.vue",
        data(){
            return{
                uniqueNo: common.getItem('uniqueNo'),
                kindList:[],
                kindChildList:[],
                checked: true,
                stockPItemtxt:'请选择',//父类code
                stockItemCode:'请选择',//子类code
                showkindList:false,
                showkindchildList:false,
                stockSearch:{
                    userName:'',//运营人员姓名
                    //checkMyself	:'' ,// 只查本人  1-是  其他都为否
                    pItemCode:'',//父类code
                    itemCode:'',//子类code
                    stationName:'',//站点名称
                },
                stationList:[],
                stationFinished:false,
                stationLoading:false,
                isLoadingStation:false,
                pageIndex:1,//页码
                pageSize:10,
            }
        },
        created() {
            this.getKindList();
        },
        methods:{
            //获取类别
            getKindList(){
                getParentItem({}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.kindList = res.data.data.parentItems.map(item=>{
                            item.text = item.pItemName;
                            delete item.pItemName;
                            return item;
                        })
                        this.kindList.unshift({text:'请选择',pItemCode:''});
                    }
                })
            },
            //选择父类别
            onConfirmKindList(value){
                this.stockPItemtxt = value.text;
                this.stockSearch.pItemCode = value.pItemCode;
                //清空子元素
                this.stockItemCode = '请选择';
                this.stockSearch.itemCode = '';
                this.pageIndex = 1
                this.stationList = [];

                getItemByPItemCode({pItemCode:value.pItemCode}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.kindChildList = res.data.data.childrens.map(item=>{
                            item.text = item.itemName;
                            delete item.itemName;
                            return item;
                        })
                        this.kindChildList.unshift({text:'请选择',itemCode:''});
                    }
                })
                this.showkindList = false;
            },
            changeStorkName(){
                if(this.stockSearch.pItemCode){
                    this.showkindchildList = true;
                }
            },
            onConfirmKindChildList(value){
                this.stockItemCode = value.text;
                this.stockSearch.itemCode = value.itemCode;
                this.showkindchildList = false;
                this.pageIndex = 1
                this.stationList = [];
            },
            stationOnLoad(){
                this.searchList();
            },
            stationOnRefresh(){
                this.isLoadingStation = false
                this.stationList = []
                this.pageIndex = 1
                this.searchList()
            },
            //切换是否本人
            switchover(){
                this.stationList = []
                this.pageIndex = 1
            },
            searchList(flag){
                if(flag){
                    this.stationList = []
                    this.pageIndex = 1
                }
                let data = {
                    checkMyself:this.checked?1:'',
                    pageIndex:this.pageIndex,//页码
                    pageSize:this.pageSize,
                    ...this.stockSearch,
                }
                outStockList(data).then(res=>{
                    this.stationLoading = false;
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.stationFinished = !res.data.data.hasNextPage;
                        if (this.pageIndex === 1) {
                            this.stationList = res.data.data.list;
                        } else {
                            this.stationList = this.stationList.concat(res.data.data.list);
                        }
                        if (!this.stationFinished) {
                            this.pageIndex++;
                        }
                    }
                    console.log('数据', this.stationList)
                })
            },
            jumpTo(){
                this.$router.push('/facilityRemoval')
            },
            otherJump(){
                this.$router.push('/otherFacilityRemoval')
            },
            cancelStockJump(){
                let arr = [];
                let oldArr = this.stationList;
                for(let i = 0; i < oldArr.length ; i++) {
                    let item = oldArr[i];
                    if(item.checked){
                        let obj = {}
                        obj.id = item.id;
                        arr.push(obj);
                    }
                }
                if(arr.length <= 0){
                    this.$message.warning('请选择需撤回的出库记录!')
                    return;
                }
                console.log('arr撤销数据',arr);
                cancelJump({transferGoodsList:arr}).then(res=>{
                    console.log('撤销数据',res);
                    if(res.code===200 && res.data.businessCode===200){
                        this.$message.success('出库撤销成功 !')
                        this.searchList();
                    }
                })

            }
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .allot-padding{
        padding:18px 15px;
        .station-title{
            font-size: 16px;
            font-weight: bold;
            color: #191F25;
        }
        .station{
            background: #E5FFED;
            border-radius: 12px;
            font-size: 12px;
            font-weight: 500;
            color: #417C53;
            padding: 5px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .station-info{
            font-size: 13px;
            font-weight: 500;
            color: #7E8185;
        }
        .station-num{
            font-size: 24px;
            font-weight: bold;
            color: #F25555;
            padding-right: 25px;
        }
    }

</style>
